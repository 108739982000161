import { useEffect, useState, createContext, useContext, useCallback } from 'react'
import { useUser as useSupaUser, useSessionContext, User } from '@supabase/auth-helpers-react'

import { UserDetails, Subscriptions, Organization } from './types'

type UserContextType = {
  accessToken: string | null
  user: User | null
  userDetails: UserDetails | null
  isLoading: boolean
  subscriptions: Subscriptions | null
  updateUserDetails: (
    updatedDetails: Partial<
      UserDetails & {
        organizations: Partial<
          Organization & {
            subscription: string | null
            subscription_id: string | null
            isShopify: boolean | null
            isStripe: boolean | null
            shopify_subscription: string | null
          }
        >
      }
    >
  ) => void
  updateSubscriptions: (
    subscription: string | null,
    subscription_id: string | null,
    isShopify: boolean | null,
    isStripe: boolean | null,
    shopify_subscription: string | null
  ) => Promise<void> // updated the return type to Promise<Subscriptions | null>
}

export const UserContext = createContext<UserContextType | undefined>(undefined)
export interface Props {
  [propName: string]: any
}

export const MyUserContextProvider = (props: Props) => {
  const { session, isLoading: isLoadingUser, supabaseClient: supabase } = useSessionContext()
  const user = useSupaUser()
  const accessToken = session?.access_token ?? null
  const [isLoadingData, setIsloadingData] = useState(false)
  const [userDetails, setUserDetails] = useState<UserDetails | null>(null)
  const [subscriptions, setSubscriptions] = useState<Subscriptions | null>(null)

  const getUserDetails = useCallback(
    () =>
      supabase
        .from('users')
        .select(
          '*, organizations:users_organization_fkey(organization_name, subscription, subscription_id, isStripe, isShopify, shopify_subscription)'
        )
        .eq('id', user?.id)
        .single(),
    [user?.id, supabase]
  )

  const getSubscription = useCallback(
    async (
      subscription: string | null | undefined,
      subscription_id: string | null | undefined
    ): Promise<Subscriptions | null> => {
      if (!subscription && !subscription_id) {
        return null
      }

      const promises = []

      if (subscription) {
        const subscriptionPlanPromise = supabase
          .from('subscription_plans')
          .select('title, quotas')
          .eq('id', subscription)
          .single()
        promises.push(subscriptionPlanPromise)
      }

      if (subscription_id) {
        const subscriptionPromise = supabase
          .from('subscriptions')
          .select(
            'id, status, created, current_period_start, current_period_end, org_id, stripe_price_id, stripe_product_id, recurring_type'
          )
          .eq('id', subscription_id)
          .single()
        promises.push(subscriptionPromise)
      }

      const results = await Promise.all(promises)

      const subscriptionPlan = results[0]?.data
      const subscriptionDetail = results[1]?.data

      console.log('getSubscription - subscriptionPlan: ', subscriptionPlan)
      console.log('getSubscription - subscription: ', subscriptionDetail)

      return {
        subscriptionPlan,
        subscriptionDetail
      } as Subscriptions
    },
    [supabase]
  )

  const updateUserDetails = useCallback(
    (
      updatedDetails: Partial<
        UserDetails & {
          organizations: Partial<
            Organization & {
              subscription: string | null
              subscription_id: string | null
              isShopify: boolean | null
              isStripe: boolean | null
              shopify_subscription: string | null
            }
          >
        }
      >
    ) => {
      setUserDetails(prevDetails =>
        prevDetails
          ? {
              ...prevDetails,
              ...updatedDetails,
              organization: updatedDetails.organizations?.id || prevDetails.organizations?.id || '',
              organizations: {
                id: updatedDetails.organizations?.id || prevDetails.organizations?.id || '',
                organization_name:
                  updatedDetails.organizations?.organization_name || prevDetails.organizations?.organization_name || '',
                subscription:
                  updatedDetails.organizations?.subscription !== undefined
                    ? updatedDetails.organizations?.subscription
                    : prevDetails.organizations?.subscription,
                subscription_id:
                  updatedDetails.organizations?.subscription_id !== undefined
                    ? updatedDetails.organizations?.subscription_id
                    : prevDetails.organizations?.subscription_id,
                isShopify:
                  updatedDetails.organizations?.isShopify !== undefined
                    ? updatedDetails.organizations?.isShopify
                    : prevDetails.organizations?.isShopify,
                isStripe:
                  updatedDetails.organizations?.isStripe !== undefined
                    ? updatedDetails.organizations?.isStripe
                    : prevDetails.organizations?.isStripe,
                shopify_subscription:
                  updatedDetails.organizations?.shopify_subscription !== undefined
                    ? updatedDetails.organizations?.shopify_subscription
                    : prevDetails.organizations?.shopify_subscription
              }
            }
          : null
      )
    },
    []
  )

  const updateSubscriptions = useCallback(
    async (subscription: string | null, subscription_id: string | null) => {
      const data = await getSubscription(subscription, subscription_id)
      setSubscriptions(data)
    },
    [getSubscription]
  )

  useEffect(() => {
    console.log('Under useUser useEffect')
    if (user && !isLoadingData && !userDetails) {
      setIsloadingData(true)
      Promise.allSettled([getUserDetails()]).then(results => {
        console.log('getUserDetails result: ', results)
        const userDetailsPromise = results[0]

        if (userDetailsPromise.status === 'fulfilled') {
          setUserDetails(userDetailsPromise.value.data as UserDetails)
          console.log('under useUser - setUserDetails:', userDetailsPromise.value.data)

          const { subscription, subscription_id } = userDetailsPromise.value.data.organizations || {}
          console.log('under useUser - subscription, subscription_id: ', subscription, subscription_id)
          getSubscription(subscription, subscription_id).then(data => {
            setSubscriptions(data)
            console.log('under useUser - setSubscription:', data)
          })
        }

        console.log('setIsloadingData false')
        setIsloadingData(false)
      })
    } else if (!user && !isLoadingUser && !isLoadingData) {
      console.log('setUserDetails & Subscription null')
      setUserDetails(null)
      setSubscriptions(null)
    }
  }, [getUserDetails, getSubscription, user, isLoadingUser, isLoadingData, userDetails])

  const value = {
    accessToken,
    user,
    userDetails,
    isLoading: isLoadingUser || isLoadingData,
    subscriptions,
    updateUserDetails,
    updateSubscriptions
  }

  //  console.log('Under useUser returning Access Token', accessToken)
  //  console.log('Under useUser returning user: ', user)
  //  console.log('Under useUser returning userDetails: ', userDetails)
  //  console.log('Under useUser returning subscriptions: ', subscriptions)
  //  console.log('Under useUser returning session: ', session)

  return <UserContext.Provider value={value} {...props} />
}

export const useUser = () => {
  const context = useContext(UserContext)
  if (context === undefined) {
    throw new Error(`useUser must be used within a MyUserContextProvider.`)
  }

  return context
}
